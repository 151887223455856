$(document).on('DOMContentLoaded', function() {
    class showPasswords {
        constructor() {
            this.btn = document.querySelectorAll('.input-group-text');
            this.bind();
        };

        bind() {
            this.glassClick()
        };

        glassClick() {
            this.btn.forEach(function(item) {
                item.addEventListener('click', function(e) {
                    this.classList.toggle('show-password');

                    if (this.previousSibling.getAttribute('type') === 'password') {
                        this.previousSibling.setAttribute('type', 'text');
                    } else {
                        this.previousSibling.setAttribute('type', 'password');
                    }
                });
            });
        }
    }
    new showPasswords;
})