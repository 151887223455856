$(document).on('DOMContentLoaded', function() {
    class Notifications {

        constructor() {
            this.btn = document.querySelectorAll('.close-jq-toast-single');
            this.bind();
        }

        bind() {
            this.hideClick();
            this.closeButton();
        };

        closeButton() {
            this.btn.forEach(function (item) {
                item.addEventListener('click', function (e) {
                    $('.jq-toast-wrap').removeClass('active');
                });
            });
        };

        hideClick() {
            setTimeout(function() {
                $('.jq-toast-wrap').removeClass('active');
            }, 3000);

            let notification = document.querySelector('.jq-toast-wrap');

            setTimeout(function() {
                if(notification){
                    $(notification).addClass('active');
                }
            }, 200);
        };
    }
    new Notifications;
});
