$(document).on('DOMContentLoaded', function() {
	class Export {
		constructor() {
			this.callButton = document.querySelector('.btn-export');
			if (this.callButton != null) {
					this.init();
			}
		}
		init() {
			this.callButton.addEventListener('click', function(e) {
					setTimeout(function() {
							$('.btn-export').removeAttr('disabled')
					}, 500)

					$('#export_dialog').modal('hide')
			})
		}
	}
	new Export;
})